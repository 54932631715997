// Déclaration Var fonts
$Raleway: 'Raleway';
$Poppins: 'Poppins';
$Ubuntu: 'Ubuntu Mono';
$SilkScreen: 'Silkscreen';


// Déclaration Var Couleurs :
// $greenblue: #0b404a;
// $darkGreenBlue: #01090A;
$lightBlueTransparent: #6ec7e050;
$lightBlue: #6ec7e0;
$darkBlue: #1e353c;
$darkBlueHaze: #1e353ccf;
$lightBlueShadow: #6ec7e0cd;
$snowWhite: #FCF7F8;

// Déclarations breakpoints : 
$sm-screen: 568px;
$md-screen: 768px;
$lg-screen: 992px;