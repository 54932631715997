@font-face {
    font-family: $Raleway;
    src: url("../fonts/RalewayRegular.ttf");
}
@font-face {
    font-family: $Poppins;
    src: url("../fonts/PoppinsRegular.ttf");
}
@font-face {
    font-family: $SilkScreen;
    src: url('../fonts/Silkscreen-Regular.ttf');
}

