@import '../../scss/variables.scss';


.navbar_header{
    width: 100%;
    position: fixed;
    display: flex;
    padding: 1em 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    background: linear-gradient(black, 85%, $lightBlueShadow);
    box-shadow: 1em 1em 10em .1em $lightBlueShadow;

    .logo-container{
        margin: 0 auto;
        .navbar_logo{
            cursor: pointer;
            width: 4vh;
            transform: scale(1);
            transition: all 1s ease-in-out;
        }
        :hover{
            transform: scale(1.2);
        }
    }
    .navbar_links{
        list-style-type: none;
        display: flex;
        padding: 0;
        margin: 0 auto;
        align-items: center;
        
        .navbar_item{
            color: $snowWhite;
            padding: 0 2em 0 0.2em ;
            font-weight: 700;
            cursor: pointer;
            position: relative;
            // transition: .4s ease-in;

            &:hover {
                color: black;
                z-index: 999;
            }

            &::before{
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 90%;
                background-color: $lightBlue;
                opacity: 0.7;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform 0.2s ease-in-out;
                z-index: -1;
            }
            &:hover::before {
                transform: scaleX(1);
            }
        }
    }
    .navbar_burger{
        width: 30px;
        height: 30px;
        background: transparent;
        border: none;
        color: inherit;
        cursor: pointer;
        // display: none;
    }
}
.navbar_header--transparent{
    transition: all 0.3s ease-in-out;
    background: transparent;
    box-shadow: none;
}

@media screen and (max-width: $md-screen ) {
    .navbar_header>.logo-container{
        margin: 0;
        padding: 0 3vw;
    }
    .navbar_links{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: -100vw;
        bottom: 0;
        height: 100vh;
        width:0;
        padding: 2rem;
        visibility: hidden;
        background-color: black;
        transition: all .8s ease-in-out;

        .navbar_link{
            display: block;
            padding: 1.2rem;
            font-size: 4vw;
        }
    }
    .show_nav .navbar_links{
        right:0;
        width:100vw;
        visibility: visible;
        .navbar_item::after {
            content: "";
            display: block;
            margin: 0 auto;
            width: 4vw;
            height: 1px;
            background-color: $lightBlue;
        }
        .navbar_item:last-child:after {
            display: none;
        }
    }
    .navbar_burger{
        display: block;
        position: fixed;
        top: 1rem;
        right: 2rem;

        .burger_bar,
        .burger_bar::after,
        .burger_bar::before{
            display: block;
            width: 30px;
            height: 2px;
            border-radius: 2px;
            position: relative;
            background-color: $snowWhite;
            transition: all .5s ease-in-out;

        }

        .burger_bar::after,
        .burger_bar::before{
            content: "";
            position: absolute;
            left: 0;
        }
        .burger_bar::before{
            transform: translateY(-10px);
        }
        .burger_bar::after{
            transform: translateY(10px);
        }
        :hover{
            cursor: pointer;
        }
    }
    .show_nav .burger_bar{
        width:0;
        background: transparent;
    }
    .show_nav .burger_bar::before{
        transform: rotate(45deg);
    }
    .show_nav .burger_bar::after{
        transform: rotate(-45deg);
    }
    // burger button animation
    .navbar_item {
        transform: translateY(100vh);
    }
    .show_nav .navbar_item{
        transform: translateY(0);
    }
    .show_nav .navbar_item.slideInDown1{
        transition: all 1s ease-in-out;
    }
    .show_nav .navbar_item.slideInDown2{
        transition: all 1.1s ease-in-out;
    }
    .show_nav .navbar_item.slideInDown3{
        transition: all 1.2s ease-in-out;
    }
    .show_nav .navbar_item.slideInDown4{
        transition: all 1.3s ease-in-out;
    }
    .show_nav .navbar_item.slideInDown5{
        transition: all 1.4s ease-in-out;
    }
    
}