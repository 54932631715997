@import '../../scss/variables.scss';

.alert-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $snowWhite;
    padding: 20px;
    box-shadow: 0px 0px 10px $lightBlueShadow;
    color: $lightBlue;
    text-align: center;
    border-radius: 15px;
        p{
        font-size: 1.5rem;
    }
}