@import '../../scss/variables.scss';
@import '../../scss/buttons.scss';


#about-me{
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background:rgba(0, 0, 0, 0.8) url('../../img/banner_top.jpg');
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    
    .img-cartoon{
        transform: scale(0);
        max-height:30vh;
        margin: 1em 5em;
        border-radius: 10px;
        box-shadow: .1em .1em 1em $lightBlue;
        animation-name: zoompic;
        animation-delay: 4s;
        animation-duration: 2s;
        animation-fill-mode: forwards;
    }
    @keyframes zoompic {
        100%{transform: scale(1);}
    }
    .presentation {
        text-align: center;
        p {
            font-family: $SilkScreen;
            color: $lightBlue;
            font-weight: 700;
            font-size: 1.2vw;
            margin-left: 0px;
        }
        .wrap-border {
            animation-duration: .6s;
            animation-name: clignoter;
            animation-iteration-count: infinite;
            transition: none;
        }
        .hidden{
            display: none;
        }
        @keyframes clignoter {
            0% {opacity:1}
            100% {opacity: 0}
        }
        .txt-display{
            font-family: $Raleway;
            color: $snowWhite;
            text-shadow: .1em .1em .3em $lightBlue;
            h1{
                font-size: 2.5vw;
            }
            h2{
                font-size: 2vw;
                color: inherit;
                font-family: inherit;
                margin: 1.2rem 0 1.5rem 0;
            }
        }
        .btn-about-me{
            color: $lightBlue;
            transform: scale(0);
            animation-delay: 5s;
            animation-duration: 2s;
            animation-name: zoomBtn;
            animation-fill-mode: forwards;
            min-width: 5vw;
            margin: 1vh auto;
            a{
                color: $lightBlue;
            }
        }
        @keyframes zoomBtn {
            100% {transform: scale(1);}
        }
    }   
}

// @media screen and (max-width: $sm-screen) {
    
// }


@media screen and (max-width: $md-screen) {
    #about-me{
        flex-direction: column;
        text-align: center;
        .img-cartoon{
            max-height:20vh;
        }
        .presentation{
            p{
                font-size: 3vw;
            }
            .txt-display{
                h1{
                    font-size: 5vw;
                }
                h2{
                    font-size: 4.5vw;
                }
            }
        }
    }
}



