@import '../../scss/variables.scss';


.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $lightBlueTransparent;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .popup-inner{
        position: relative;
        border-radius: 1rem;
        padding: 1.2rem;
        width: 100%;
        max-width: 640px;
        height: 80vh;
        background-color: $snowWhite;
        color: black;
        overflow-y: scroll;
        // a {
        //     color: black;
        // }

        // Style scrollbar Popup
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-track {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $lightBlue;
            border-radius: 0 12px 12px 0;
        }
    
        .thumb-image-content2{
            display: flex;
            justify-content: space-evenly;
            padding: 1rem;
            p{
                padding: 0 1rem;
            }
            .img-project{
                height: 400px;
                width: auto;
                border-radius: 15px;
            }
        }
        .stacks-container{
            display: flex;
            justify-content: space-evenly;
            .stackFront-container, .stackBack-container{
                .skill-container{
                    text-align: center;
                    i {
                        color: $darkBlue;
                        font-size: 2rem;
                        --fa-animation-duration: 3s;
                        --fa-beat-scale: 1.1;
                        span {
                            font-size: .6rem;
                        }
                    }
                    p{
                        margin: 0.8rem 0 0.4rem ;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $md-screen) {
    .popup{
        .popup-inner{
            width: 80%;
            .thumb-image-content2{
                display: inherit;
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: $sm-screen) {
    .popup{
        .popup-inner{
            // width: 80%;
            .thumb-image-content2{
                display: flex;
                flex-direction: column;
                .img-project{
                    height: fit-content;
                    width: auto;
                    border-radius: 15px;
                }
            }
        }
    }
}

