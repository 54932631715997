@import '../../scss/variables.scss';

#portfolio{
    margin-bottom: 6rem;
    .title {
        text-align: center;
    }
    .thumbs-container{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
       .thumb-container{
           text-align: center;
           .thumb{
               position: relative;
               display: flex;
               justify-content: center;
               .thumb-preview{
                    font-size: .8rem;
                    background-color: $darkBlue;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: .3s all ease-in-out;
                    &:hover{
                        cursor: pointer;
                        opacity: .8;
                    }
                }
                .img-thumb{
                    height: 300px;
                    width: auto;
                    border: 2px solid $snowWhite;
                }
                .thumb-bottom{
                    font-size: .6rem;
                    width: 100%;
                }
            }
        }
    }
    
}

