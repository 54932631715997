@import '../../scss/variables.scss';

.footer{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1em;
    #footer-links{
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;
        .footer-link{
            padding: 0 .5rem;
            cursor: pointer;
            transition: all .2s ease-in-out;
            a:hover{
                color:$lightBlue;
            }
            &:hover{
                transform: translateY(-0.3vh);
                // A supp une fois lien plan du site OK
                color:$lightBlue;

            }
        }
    }
}

@media screen and (max-width: $sm-screen) {
    .footer{
        font-size: .6rem;
        #footer-links{
            .footer-link{
                padding: 0 auto;
            }
        }
    }
}