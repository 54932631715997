@import '../../scss/variables.scss';

#status{
    height: 25vh;
    background-image: url('../../img/pexels-manuel-geissinger-325229.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    
    h2{
        font-size: 2rem;
        color: inherit;
        font-family: inherit;
        margin: inherit;
    }
    p{
        font-size: 1.9rem;
    }
}