@import '../../scss/variables.scss';

.not-found-container{
    height: 86vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .redirect-box{
        margin: 0 auto;
        width: 50%;
        text-align: center;
        p{
            font-size: 1.2rem;
        }
    }
}