@import '../../scss/variables.scss';

.legal-notice{
    background-color: $snowWhite;
    color: $darkBlue;
    margin: 0;
    padding: 6rem;
    h1{
        margin-top: 0;
        text-align: center;
    }
    a{
        color: $darkBlue;
        transition: .2s all ease-in-out;
        position: relative;
        z-index: 0;
        font-weight: bold;

        &::before{
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:102%;
            background-color: $lightBlue;
            opacity: 0.7;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.2s ease-in-out;
            z-index:-1;
        }
        &:hover::before {
            transform: scaleX(1);
        }
    }
}

@media screen and (max-width: $sm-screen) {
    .legal-notice{
        padding: 5rem 1.5rem 2rem;
        font-size:.8rem;
        // text-align: center;
    }
}
