@import '../../scss/variables.scss';



.profil-container{
    display:flex;
    max-width: 1800px;
    margin: 0 auto;
    padding: 2rem;
    align-items: center;

    .profil-presentation{
        display: flex;
        flex-direction: column;
        align-items: center;

        .profil-title{
            // font-size: 2rem;
        }
        .profil-presentation-txt{
            width: 75%;
            padding-left: 5vw;
            a, span {
                color: $lightBlue;
                transition: .2s ease-in-out;
                text-decoration: none;
            }
            a:hover{
                color: $snowWhite;
                font-weight: 700;
            }
            a {
                font-family: $SilkScreen;
            }
        }
    }
    .profil-pict{
        min-width: 25%;
        position: relative;

        .profil-pict-main{
            width: 40%;
            border-radius: 10px;
            border: 2px solid $lightBlue;
            display:flex;
            margin: 0 auto;
        }
        .animation-pict{
            position: absolute;
            top: -20%;
            left:10%;
            .circle-animated{
                position: absolute;
                height: 200px;
                width: 200px;
                border-radius: 100px;
                border: 3px solid $lightBlue;
                background-color: transparent;
                color: red;
            }
        }
    }
}

@media screen and (max-width: $md-screen) {
    .profil-container{
        flex-direction: column;
        .profil-presentation{
            // .profil-title{
            //     font-size: 1.5rem;
            // }
            .profil-pict{
                margin-top: 3vh;
                width: 60%;
            }
        }
    }
}